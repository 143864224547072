
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { Action, State as StateClass } from 'vuex-class';
  import firebase from 'firebase/app';
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import { Payment } from '@/models/investments/Investment';
  import { timestampToDate } from '@/filters/date';
  import { State } from '@/models/State';
  import { Asset } from '@/models/assets/Asset';
  import { DataContainerStatus } from '@/models/Common';
  import LoanPaymentsTable from './LoanPaymentsTable.vue';
  import PaymentActionModal from './PaymentActionModal.vue';

  @Component({
    components: {
      LoanPaymentsTable,
      PaymentActionModal,
    },
  })
  export default class InvestmentsLoans extends Vue {
    @Action(addToastMessage) addToastMessage!: Function;

    @StateClass loanPayments!: State['loanPayments'];
    @StateClass payment!: State['payment'];

    showModal = false;
    isEndOrRemoveProcessing = false;
    paymentAction: 'cancel' | 'end' | 'remove' | null = null;
    selectedPayment: Payment | null = null;
    selectedInvestment: any = null

    paymentColumns = [
      'asset.name',
      'investor.name',
      'investor.surname',
      'paymentDateTimeToShow',
      'endDateTime',
      'providerData.metadata.euroAmount',
      'sharesAmount',
      'dividendsFormat',
      'provider',
      'providerData.status',
      'activityStatus',
      'updatedDateTime',
      'dropdown',
    ];

    paymentOptions = {
      headings: {
        'asset.name': 'Fund',
        'investor.name': 'Name',
        'investor.surname': 'Surname',
        paymentDateTimeToShow: 'Payment date',
        'providerData.metadata.euroAmount': 'Total (€)',
        sharesAmount: 'Shares',
        dividendsFormat: 'Interests',
        provider: 'Payment channel',
        'providerData.status': 'Status',
        activityStatus: '',
        endDateTime: 'End date',
        updatedDateTime: 'Last updated',
        dropdown: '',
      },
      sortable: [
        'asset.name',
        'investor.name',
        'investor.surname',
        'paymentDateTimeToShow',
        'providerData.metadata.euroAmount',
        'sharesAmount',
        'dividendsFormat',
        'endDateTime',
        'updatedDateTime',
      ],
      customSorting: {
        dividendsFormat(ascending) {
          return (a, b) => {
            const [yearA, intA] = a.dividendsFormat;
            const [yearB, intB] = b.dividendsFormat;
            const aBigger = (yearA > yearB) || (yearA === yearB && intA >= intB);
            if (ascending) {
              return aBigger ? 1 : -1;
            }
            // descending
            return !aBigger ? 1 : -1;
          };
        },
      },
      // columnsClasses strings need to have a space at the end
      // because vue-tables-2 adds classes runtime without a space before
      columnsClasses: {
        dropdown: 'table__col--dropdown align-middle ',
      },
      skin: 'table table-sm table-nowrap card-table table--fixed', // This will add CSS classes to the main table
    };

    @Watch('payment.status')
    onNewPaymentRequestStatus(newStatus: DataContainerStatus, oldStatus: DataContainerStatus): void {
      if (newStatus !== oldStatus && (this.payment?.operation === 'deletePayment' || this.payment?.operation === 'endPayment')) {
        if (newStatus === DataContainerStatus.Success) {
          this.addToastMessage({
            text: this.payment?.operation === 'deletePayment' ? 'Payment deleted.' : 'Payment ended',
            type: 'success',
          });

          this.showModal = false;
          this.isEndOrRemoveProcessing = false;
        } else if (newStatus === DataContainerStatus.Error) {
          this.addToastMessage({
            text: this.payment.error?.message || 'Ending payment error.',
            type: 'danger',
          });
          this.isEndOrRemoveProcessing = false;
        } else {
          this.isEndOrRemoveProcessing = true;
        }
      }
    }

    get paymentData(): any {
      return this.loanPayments.map((payment: Payment): any => ({
        ...payment,
        id: payment.id,
        asset: {
          ...payment.asset,
          id: payment.asset.id,
        },
        investment: {
          ...payment.investment,
          id: payment.investment.id,
        },
        investor: {
          ...payment.investor,
          id: payment.investor.id,
        },
        paymentDateTimeToShow: this.getPaymentDate(payment),
        // calculated here sharesAmount and add it to the object so that sorting works
        sharesAmount: payment.providerData.metadata.sharesAmount,
      }));
    }

    getPaymentDate(payment: Payment): Date {
      return timestampToDate((payment.paymentDateTime || payment.createdDateTime) as firebase.firestore.Timestamp)!;
    }

    selectPaymentAction(paymentId: string, action: 'end' | 'remove'): void {
      // find the actual member of payments in order to pass down the actual payment object, thus de-coupling it from changes in the table
      // the paymentId is selected from the loans table
      this.selectedPayment = this.paymentData.find((payment: Payment) => payment.id === paymentId);
      this.paymentAction = action;
      this.showModal = true;
    }
  }
