
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import to from 'await-to-js';
  import { Action } from 'vuex-class';
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import { bloqifyFirestore, firebase } from '@/boot/firebase';
  import { Investment, Earning } from '@/models/investments/Investment';

  type Toaster = { text: string, type: 'info' | 'success' | 'warning' | 'danger', dismissAfter?: number };
  @Component({})
  export default class EarningsTable extends Vue {
    @Prop() investment?: Investment;
    @Action(addToastMessage) addToastMessage!: (arg0: Toaster) => any;
    earnings: null | Earning[] = null;
    loading = true;
    errorLoading = false;
    columns = [
      'period',
      'amount',
    ];
    options = {
      headings: {
        period: 'Period',
        amount: 'Amount',
      },
      descOrderColumns: ['period'],
      filterable: [
        'period',
        'amount',
      ],
      skin: 'table table-sm table-nowrap card-table table--fixed', // This will add CSS classes to the main table,
    };
    @Watch('investment', { immediate: true })
    async onNewInvestment(newInvestment: Investment): Promise<void> {
      if (!newInvestment?.id) {
        return;
      }

      const [earningsQueryError, earningsQuery] = await to(bloqifyFirestore.collection(`investments/${newInvestment.id}/earnings`)
        .where('deleted', '==', false)
        .get() as Promise<firebase.firestore.QuerySnapshot<Earning>>);

      if (earningsQueryError || !earningsQuery || earningsQuery.empty) {
        this.addToastMessage({
          text: 'Issue loading interests',
          type: 'danger',
        });
        throw Error(earningsQueryError?.message || 'There was an error fetching the interest');
      }

      this.earnings = earningsQuery.docs.map((doc): any => ({
        period: (doc.get('period') as firebase.firestore.Timestamp).toDate(),
        amount: doc.get('amount'),
      }));

      const totalEarnings = this.earnings.reduce((sum, earning): number => (earning.amount ? sum + earning.amount : sum), 0);

      this.$emit('setTotalEarnings', totalEarnings);
      this.loading = false;
    }

    roundNumber(num, scale): number {
      // eslint-disable-next-line no-restricted-properties
      const precision = Math.pow(10, scale);
      return Math.round((num + Number.EPSILON) * precision) / precision;
    }
  }
