
  import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

  @Component({
  })
  export default class InvestmentsTable extends Vue {
    @Prop() columns: any
    @Prop() data: any
    @Prop() options: any
  }
