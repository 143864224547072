
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { Action, State as StateClass } from 'vuex-class';
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import json2csv from 'json2csv/dist/json2csv.umd';
  import to from 'await-to-js';
  import FileSaver from 'file-saver';
  import { sortBy } from 'lodash';
  import { firebase, bloqifyFirestore } from '@/boot/firebase';
  import { State } from '@/models/State';
  import { DataContainerStatus } from '@/models/Common';
  import { GetCollectionParams } from '@/store/actions';
  import { Investment, Payment, PaymentStatus } from '@/models/investments/Investment';
  import { timestampToDate, transformDate } from '@/filters/date';
  import { Asset } from '@/models/assets/Asset';
  import { Investor, KYCMethods } from '@/models/users/User';
  import EarningsTable from '@/components/investments/EarningsTable.vue';
  import InvestmentsTable from './InvestmentsTable.vue';
  import InvestmentPaymentsTable from './InvestmentPaymentsTable.vue';
  import PaymentActionModal from './PaymentActionModal.vue';

  interface InvestmentInTable extends Omit<Investment, 'investor'> {
    investor: Pick<Investor, 'name' | 'surname' | 'id'>;
  }

  type View = 'investments' | 'payments' | 'earnings'

  @Component({
    components: {
      EarningsTable,
      InvestmentsTable,
      InvestmentPaymentsTable,
      PaymentActionModal,
    },
  })
  export default class InvestmentsAll extends Vue {
    totalEarnings = null;
    loadingInvestments = false;
    loadingPayments: Promise<any> = Promise.resolve();
    loadingFilters = false;
    investments: Investment[] = [];
    assets: Asset[] = [];
    assetsObject: { [key: string]: Asset } = {};
    investors: Investor[] = [];
    investorsObject: { [key: string]: Investor } = {};
    investmentsUnsubscribe: undefined | Function;
    assetsUnsubscribe: undefined | Function;
    investorsUnsubscribe: undefined | Function;

    view: View = 'investments';
    showModal = false;
    isEndOrRemoveProcessing = false;
    investmentColumns = ['fund', 'name', 'surname', 'updatedDateTime', 'paidEuroTotal', 'showPayments', 'showEarnings'];
    investmentOptions = {
      headings: {
        fund: 'Fund',
        name: 'Name',
        surname: 'Surname',
        updatedDateTime: 'Last Update',
        paidEuroTotal: 'Total',
        showPayments: '',
        showEarnings: '',
      },
      filterable: ['fund', 'name', 'surname', 'paidEuroTotal'],
      // columnsClasses strings need to have a space at the end
      // because vue-tables-2 adds classes runtime without a space before
      columnsClasses: {
        fund: 'table__col--fund align-middle table__col--l ',
        name: 'table__col--name align-middle table__col--s ',
        surname: 'table__col--surname align-middle table__col--s ',
        updatedDateTime: 'table__col--updatedDateTime align-middle table__col--s ',
        paidEuroTotal: 'table__col--paidEuroTotal text-right font-weight-bold align-middle table__col--xs ',
        showPayments: 'table__col--showPayments text-right font-weight-bold align-middle table__col--m ',
        showEarnings: 'table__col--showEarnings text-right font-weight-bold align-middle table__col--m ',
        dropdown: 'table__col--dropdown ',
      },
      orderBy: {
        ascending: false,
        column: 'updatedDateTime',
      },
      skin: 'table table-sm table-nowrap card-table table--fixed', // This will add CSS classes to the main table
    };

    paymentColumns = [
      'paymentDateTimeToShow', 'providerData.metadata.euroAmount', 'sharesAmount', 'dividendsFormat',
      'provider', 'providerData.status', 'activityStatus', 'endDateTime', 'updatedDateTime', 'dropdown',
    ];
    paymentOptions = {
      headings: {
        paymentDateTimeToShow: 'Payment date',
        'providerData.metadata.euroAmount': 'Total (€)',
        sharesAmount: 'Shares',
        dividendsFormat: 'Dividends',
        provider: 'Payment channel',
        'providerData.status': 'Status',
        activityStatus: 'Activity Status',
        endDateTime: 'End date',
        updatedDateTime: 'Last updated',
        dropdown: '',
      },
      filterable: false,
      sortable: ['paymentDateTimeToShow', 'providerData.metadata.euroAmount', 'sharesAmount', 'dividendsFormat', 'updatedDateTime'],
      orderBy: {
        ascending: false,
        column: 'paymentDateTimeToShow',
      },
      customSorting: {
        dividendsFormat(ascending) {
          return (a, b) => {
            const [yearA, intA] = a.dividendsFormat;
            const [yearB, intB] = b.dividendsFormat;
            const aBigger = (yearA > yearB) || (yearA === yearB && intA >= intB);
            if (ascending) {
              return aBigger ? 1 : -1;
            }
            // descending
            return !aBigger ? 1 : -1;
          };
        },
      },
      // columnsClasses strings need to have a space at the end
      // because vue-tables-2 adds classes runtime without a space before
      columnsClasses: {
        dropdown: 'table__col--dropdown align-middle ',
      },
      skin: 'table table-sm table-nowrap card-table table--fixed', // This will add CSS classes to the main table
    };

    investmentExtraInfoData: {
        investorId?: string,
        investorName?: string,
        assetId?: string,
        fundName?: string,
        totalInvested?: number
    } | null = null;
    selectedInvestment: { [key: string]: any } = {};
    selectedPayment: Payment | null = null;
    paymentAction: 'end' | 'remove' | null = null;

    @Action setFilter!: (data: { collection: string, field: string, value: string | boolean }) => {};
    @Action resetFilters!: (data: { collection: string }) => {};
    @Action bindFirestoreReference!: Function;
    @Action unbindFirestoreReference!: Function;
    @Action(addToastMessage) addToastMessage!: Function;

    @StateClass boundPayments!: State['boundPayments'];
    @StateClass payment!: State['payment'];
    @StateClass filters!: State['filters'];

    @Watch('payment.status')
    onNewPaymentRequestStatus(newStatus: DataContainerStatus, oldStatus: DataContainerStatus): void {
      if (newStatus !== oldStatus) {
        if (newStatus === DataContainerStatus.Success) {
          if (this.payment?.operation === 'deletePayment') {
            this.addToastMessage({
              text: 'Payment deleted',
              type: 'success',
            });
          }

          if (this.payment?.operation === 'endPayment') {
            this.addToastMessage({
              text: 'Payment ended',
              type: 'success',
            });
          }

          if (this.payment?.operation === 'cancelPayment') {
            this.addToastMessage({
              text: 'Payment canceled',
              type: 'success',
            });
          }

          this.showModal = false;
          this.isEndOrRemoveProcessing = false;
        } else if (newStatus === DataContainerStatus.Error) {
          this.addToastMessage({
            text: this.payment?.error?.message || 'Ending payment error.',
            type: 'danger',
          });
          this.isEndOrRemoveProcessing = false;
        } else {
          this.isEndOrRemoveProcessing = true;
        }
      }
    }

    setTotalEarnings(totalEarnings): void {
      this.totalEarnings = totalEarnings;
    }

    removeSelectedEarnings(): void {
      if (this.totalEarnings) {
        this.totalEarnings = null;
      }
    }

    mounted(): void {
      this.loadingInvestments = true;
      this.loadingFilters = true;

      // Loading investments
      this.investmentsUnsubscribe = InvestmentsAll.parseWheres(
        [
          ...this.investmentsQueryObject.where || [],
        ],
        bloqifyFirestore.collection('investments')
          .orderBy('updatedDateTime', 'desc'),
      ).onSnapshot((snapshot): void => {
        this.investments = snapshot.docs.map((doc): Investment => ({ ...doc.data() as Investment, id: doc.id }));
        this.loadingInvestments = false;
      });

      // Loading investors
      this.investorsUnsubscribe = bloqifyFirestore.collection('investors')
        .orderBy('surname', 'asc')
        .onSnapshot((snapshot): void => {
          this.investors = snapshot.docs.map((doc): Investor => {
            const investor: Investor = {
              ...doc.data() as Investor,
              id: doc.id,
            };
            this.investorsObject[doc.id] = investor;
            return investor;
          });
          this.loadingFilters = false;
        });

      // Load assets
      this.assetsUnsubscribe = bloqifyFirestore.collection('assets').where('deleted', '==', false)
        .onSnapshot((snapshot): void => {
          this.assets = snapshot.docs.map((doc): Asset => {
            const asset: Asset = {
              ...doc.data() as Asset,
              id: doc.id,
            };
            this.assetsObject[doc.id] = asset;
            return asset;
          });
          this.loadingFilters = false;
        });
    }

    beforeDestroy(): void {
      if (!this.$route.fullPath.includes('investments')) {
        this.resetFilters({ collection: 'investments' });
        this.unbindFirestoreReference({ name: 'boundPayments' });
      }
      this.investmentsUnsubscribe!();
      this.investorsUnsubscribe!();
      this.assetsUnsubscribe!();
    }

    get investmentsQueryObject(): GetCollectionParams {
      const { byAsset, byInvestor } = this.filters.investments;
      const where: any[] = [];

      if (byAsset?.value) {
        where.push(['asset', '==', bloqifyFirestore.collection('assets').doc(byAsset.value)]);
      }
      if (byInvestor?.value) {
        where.push(['investor', '==', bloqifyFirestore.collection('investors').doc(byInvestor.value)]);
      }

      return { assetId: byAsset?.value, investorId: byInvestor?.value, where };
    }

    get assetOptions(): { value: string, text: string }[] {
      let assets = [...this.assets || []];
      if (this.filters.investments.byPublished) {
        assets = assets.filter((asset): boolean => asset.published);
      }
      return assets.map((asset): any => ({ value: asset.id, text: asset.name, published: asset.published }));
    }

    get selectedAssetName(): string {
      if (!this.filters.investments.byAsset) {
        return '';
      }

      const foundAsset = this.assetOptions.find((opt): boolean => opt.value === this.filters.investments.byAsset!.value);
      return foundAsset ? foundAsset.text : '';
    }

    get investorOptions(): { value: string, text: string }[] {
      return this.investors.map((investor): any => ({
        value: investor.id,
        text: `#${investor.customId} ${investor.name || ''} ${investor.surname || ''}`,
      }));
    }

    get selectedInvestorName(): string {
      if (!this.filters.investments.byInvestor) {
        return '';
      }

      const foundInvestor = this.investorOptions.find((opt): boolean => opt.value === this.filters.investments.byInvestor!.value);
      return foundInvestor ? foundInvestor.text : '';
    }

    get investmentData(): InvestmentInTable[] {
      if (!this.assets.length) {
        return [];
      }

      return this.investments.reduce((prev, inv): InvestmentInTable[] => {
        const { asset: assetRef, investor: investorRef } = inv;
        const asset = this.assetsObject[assetRef.id!];

        if (!asset) {
          return prev;
        }

        if (!investorRef.id) {
          return prev;
        }

        const investor = this.investorsObject[investorRef.id];
        if (!investor) { // this can happen when the timing is not right
          return prev;
        }
        const { id, name, surname } = investor;

        if ((this.filters.investments.byPublished && !asset.deleted && asset.published)
          || (!this.filters.investments.byPublished && !asset.deleted)
        ) {
          prev.push({
            ...inv,
            investor: { id, name, surname },
            asset,
          });
        }

        return prev;
      }, [] as InvestmentInTable[]);
    }

    get paymentData(): any {
      return (this.boundPayments as Payment[])
        .map((payment: Payment): any => ({
          ...payment,
          id: payment.id,
          investment: {
            ...payment.investment,
            id: payment.investment.id,
          },
          paymentDateTimeToShow: this.getPaymentDate(payment),
          // add sharesAmount to the object so that sorting works
          sharesAmount: payment.providerData.metadata.sharesAmount,
        }));
    }

    inputInvestorFilter(selectedObject): void {
      selectedObject = selectedObject || false;
      this.selectFilter('byInvestor', selectedObject);
    }

    inputAssetFilter(selectedObject): void {
      selectedObject = selectedObject || false;
      this.selectFilter('byAsset', selectedObject);
    }

    // Hide text, show table and send request
    getFilteredInvestments(reQuery: boolean): void {
      this.selectedInvestment = {};
      this.removeSelectedEarnings();
      this.view = 'investments';

      if (reQuery) {
        this.investmentsUnsubscribe!();
        this.loadingInvestments = true;
        this.investmentsUnsubscribe = InvestmentsAll.parseWheres(
          [
            ...this.investmentsQueryObject.where || [],
          ],
          bloqifyFirestore.collection('investments')
            .orderBy('updatedDateTime', 'desc'),
        ).onSnapshot((snapshot): void => {
          this.investments = snapshot.docs.map((doc): Investment => ({ ...doc.data() as Investment, id: doc.id }));
          this.loadingInvestments = false;
        });
        return;
      }

      this.unbindFirestoreReference({ name: 'boundPayments' });
    }

    selectFilter(field: string, value: string | boolean): void {
      this.setFilter({ collection: 'investments', field, value });
    }

    getPaymentDate(payment: Payment): Date {
      return timestampToDate((payment.paymentDateTime || payment.createdDateTime) as firebase.firestore.Timestamp)!;
    }

    // Reset all fields and reload the list
    async resetAndReload(): Promise<void> {
      this.selectedInvestment = {};
      this.investmentsUnsubscribe!();
      this.loadingInvestments = true;
      this.investmentsUnsubscribe = bloqifyFirestore.collection('investments')
        .orderBy('updatedDateTime', 'desc')
        .onSnapshot((snapshot): void => {
          this.investments = snapshot.docs.map((doc): Investment => ({ ...doc.data() as Investment, id: doc.id }));
          this.loadingInvestments = false;
          this.resetFilters({ collection: 'investments' });
        });
    }

    showPayments(investment: { [key: string]: any }): void {
      this.selectedInvestment = { ...investment };
      // We don't want to lose the non-enumerable id coming from the bindings
      Object.defineProperty(this.selectedInvestment, 'id', { value: investment.id });
      this.loadingPayments = this.bindFirestoreReference({
        name: 'boundPayments',
        ref: bloqifyFirestore.collection('investments')
          .doc(investment.id).collection('payments')
          .where('deleted', '==', false)
          .orderBy('createdDateTime', 'desc'),
      });
      this.view = 'payments';
    }

    @Watch('investments')
    investmentsExtraInfo(): void {
      this.investmentExtraInfoData = null;

      if (this.filters.investments.byInvestor) {
        this.investmentExtraInfoData = {
          investorName: this.filters.investments.byInvestor.text,
          investorId: this.filters.investments.byInvestor.value,
        };
      }

      if (this.filters.investments.byAsset) {
        this.investmentExtraInfoData = {
          ...this.investmentExtraInfoData,
          assetId: this.filters.investments.byAsset.value,
          fundName: this.filters.investments.byAsset.text,
        };
      }

      if (this.investmentExtraInfoData) {
        let totalInvested: number = 0;
        totalInvested = this.investments.reduce((sum, investment): number => (investment.paidEuroTotal ? sum + investment.paidEuroTotal : sum), 0);
        this.investmentExtraInfoData = {
          ...this.investmentExtraInfoData,
          totalInvested,
        };
      }
    }

    showEarnings(investment: { [key: string]: any }): void {
      this.view = 'earnings';
      this.selectedInvestment = { ...investment };
      // We don't want to lose the non-enumerable id coming from the bindings
      Object.defineProperty(this.selectedInvestment, 'id', { value: investment.id });
    }

    selectPaymentAction(paymentId: string, action: 'end' | 'remove'): void {
      // find the actual member of payments in order to pass down the actual payment object, thus de-coupling it from changes in the table
      // the paymentId is selected from the payments table
      this.selectedPayment = this.paymentData.find((payment: Payment) => payment.id === paymentId);
      this.paymentAction = action;
      this.showModal = true;
    }

    /**
     * Function that adds a wheres into a single query/ref
     */
    static parseWheres(
      wheres: [string | firebase.firestore.FieldPath, firebase.firestore.WhereFilterOp, any][],
      ref: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> | firebase.firestore.Query<firebase.firestore.DocumentData>,
    ): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> | firebase.firestore.Query<firebase.firestore.DocumentData> {
      wheres.forEach((where): void => {
        ref = ref.where(...where);
      });
      return ref;
    }

    /**
     * Returns whether current browser allows file downloads.
     */
    get fileDownloadIsSupported(): boolean {
      try {
        return !!new Blob();
      } catch (e) {
        return false;
      }
    }

    /**
     * Execute investors download.
     */
    async exportInvestmentsPerPayment(): Promise<void> {
      const [getPaymentsError, getPaymentsSuccess] = await to(
        bloqifyFirestore.collectionGroup('payments')
          .where('providerData.status', '==', PaymentStatus.Paid)
          .where('deleted', '==', false)
          .get(),
      );

      if (getPaymentsError) {
        this.addToastMessage({
          text: 'Something went wrong while fetching all payments',
          type: 'danger',
        });
        return;
      }

      const investmentsPerPayment = await Promise.all(getPaymentsSuccess!.docs.map(async (doc) => {
        const [getInvestorError, getInvestorSuccess] = await to(
          bloqifyFirestore.collection('investors').doc(doc.get('investor').id).get(),
        );

        if (getInvestorError) {
          this.addToastMessage({
            text: 'Something went wrong while fetching the investor for an investment',
            type: 'danger',
          });
        }

        const [getAssetError, getAssetSuccess] = await to(
          bloqifyFirestore.collection('assets').doc(doc.get('asset').id).get(),
        );

        if (getAssetError) {
          this.addToastMessage({
            text: 'Something went wrong while fetching the asset for an investment',
            type: 'danger',
          });
        }

        return {
          id: getInvestorSuccess!.get('customId'),
          Name: getInvestorSuccess!.get('name'),
          Surname: getInvestorSuccess!.get('surname'),
          Email: getInvestorSuccess!.get('email'),
          Fund: getAssetSuccess!.get('name'),
          Date: transformDate(doc.get('updatedDateTime')),
          'Bought Shares': doc.get('providerData.metadata.sharesAmount'),
          'Paid Euro': doc.get('providerData.metadata.euroAmount'),
          'Bank Account': getInvestorSuccess!.get('bankAccount'),
          Telephone: getInvestorSuccess!.get('telephone'),
          'Street Address': getInvestorSuccess!.get('streetAddress'),
          'House Number': getInvestorSuccess!.get('houseNumber'),
          City: getInvestorSuccess!.get('city'),
          'Postal Code': getInvestorSuccess!.get('postalCode'),
          Nationality: getInvestorSuccess!.get('nationality'),
          Country: getInvestorSuccess!.get('country'),
          'National Register Number': getInvestorSuccess!.get('nationalRegisterNumber'),
        };
      }));

      const sortedInvestmentsPerPayment = sortBy(investmentsPerPayment, ['Fund', 'Name', 'Paid Euro']);

      const BOM = '\uFEFF';
      const csv = BOM + json2csv.parse(sortedInvestmentsPerPayment);

      // Present file download
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      FileSaver.saveAs(blob, 'investments.csv');
    }
  }
