
  import firebase from 'firebase/app';
  import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
  import { Getter } from 'vuex-class';
  import { convertUTCToLocalDate } from '@/filters/date';
  import { Payment, PaymentStatus } from '@/models/investments/Investment';
  import { ManagerRole } from '@/models/manager/Manager';

  @Component({})
  export default class PaymentsTable extends Vue {
    @Prop() columns: any
    @Prop() data: any
    @Prop() options: any
    @Prop() selectedInvestment: any

    @Getter getCurrentManagerRole!: ManagerRole;

    /**
     * Allow end payment.
     */
    get allowEndPayment(): boolean {
      return this.getCurrentManagerRole === ManagerRole.Superadmin || this.getCurrentManagerRole === ManagerRole.Admin;
    }

    isRemovable(payment: Payment): boolean {
      return payment.providerData.status !== PaymentStatus.Open;
    }

    isEndable(payment: Payment): boolean {
      return payment.providerData.status === PaymentStatus.Paid && !payment.ended;
    }

    isCancelable(payment: Payment): boolean {
      return payment.providerData.status === PaymentStatus.Open;
    }

    // Currently same as isEndable but not removing it becuase
    // 1. We might need it for finer control of allowed actions
    // 2. Meerdevoort branch might rely on its existence for migration
    isModifiable(payment: Payment): boolean {
      return payment.providerData.status === PaymentStatus.Paid && !payment.ended;
    }

    /**
     * Determines whether any action can be done on the given payment
     */
    isActionable(payment: Payment): boolean {
      return this.isCancelable(payment) || this.isRemovable(payment) || this.isEndable(payment) || this.isModifiable(payment);
    }

    isEndDatePassed(payment: Payment): boolean {
      if (!payment.ended) {
        return false;
      }

      // Transforming the ended Timestamp to milliseconds to compare it with the current date (also in ms)
      const endDate = convertUTCToLocalDate(payment.ended as firebase.firestore.Timestamp)!;
      return endDate.getTime() <= Date.now();
    }
  }
